import { NextSeo } from 'next-seo'
import Head from 'next/head'

const theHTML = `
<div class="text-white overflow-hidden" style="background-color: #0D2344; position: relative; z-index: 0;">
  <img class="ics2021-cityscape absolute z-10 left-0 right-0 w-full h-auto object-cover object-top" style="top: -150px; min-height: 500px" src="/img/ics2021/background.jpg" alt="">
  <div class="relative z-20">
    <div
    class="px-4 pt-20 pb-6 lg:pb-0"
    >
    <div class="relative overflow-hidden mx-auto max-w-6xl" style="padding-bottom: 50%">
      <img class="absolute top-0 left-0 right-0 bottom-0" src="/img/ics2021/logo/logo.png" alt="">
    </div>
  </div>
</div>

<h2 class="mt-4 mb-6 max-w-4xl mx-auto uppercase text-xxl text-center z-40 relative px-4">Voting is now closed</h2>

<div class="flex justify-center relative z-50 mb-6">
  <div class="block py-2 hover:bg-black">
    <a class=" mx-auto px-6 tracking-wider white-space-no-wrap uppercase cursor-point font-condensed transition-all no-underline whitespace-no-wrap disabled:bg-gray-600 border-1 text-lg lg:text-xl border-white text-white" href="#finalists" type="" style="text-decoration: none; padding-top: .7rem; padding-bottom: .55rem; ">View Finalists</a>

  </div>
</div>

<div class="relative z-40">

  <div class="mx-auto max-w-3xl px-4 pt-20 xl:pt-40  text-center">
  <div class="">
    <h3>Prizes provided by:</h3>
  </div>
  <ul class="grid grid-cols-3 md:grid-cols-4 gap-4 pb-6">
            <li>
      <a href="https://www.holley.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-holley.png" alt="Holley">
      </a>
    </li>
        <li>
      <a href="https://www.hemmings.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-hemmings.png" alt="Hemmings">
      </a>
    </li>
        <li>
      <a href="https://www.painlessperformance.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-painless.png" alt="Painless">
      </a>
    </li>
        <li>
      <a href="https://www.rustoleum.ca/product-catalog/consumer-brands/automotive" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-rustoleum.png" alt="Rustoleum">
      </a>
    </li>
        <li>
      <a href="https://www.ansell.com/ca/en/industrial/industries/automotive" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-ansell.png" alt="Ansell">
      </a>
    </li>
        <li>
      <a href="https://www.flex-a-lite.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-flex-a-lite.png" alt="Flex-a-lite">
      </a>
    </li>
        <li>
      <a href="https://www.oshcut.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-oshcut.png" alt="Oshcut">
      </a>
    </li>
        <li>
      <a href="https://www.milwaukeetool.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-milwaukee.png" alt="Milwaukee">
      </a>
    </li>
        <li>
      <a href="https://peakauto.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-peak.png" alt="Peak">
      </a>
    </li>
        <li>
      <a href="https://www.wd40.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-wd40.png" alt="WD-40">
      </a>
    </li>
        <li>
      <a href="https://www.summitracing.com" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-summit.png" alt="Summit">
      </a>
    </li>
        <li>
      <a href="https://www.mandrelbending.ca" target="_blank">
        <img src="https://debossgarage.s3.amazonaws.com/car-show-2021/sponsor-lyells.png" alt="Lyells">
      </a>
    </li>


  </ul>
  <p>Thank you to all of our prize sponsors and partners who help to make the Internetional Car Show possible! Please support the companies that support our industries!</p>
</div>

  <div class="py-10 text-center">

            <div class=" pt-v2">
        <div class="mx-auto max-w-5xl px-4">
          <div class="relative overflow-auto bg-white" style="padding-bottom: 56.25%;">
          <iframe class="absolute top-0 right-0 bottom-0 left-0 h-full w-full" src="https://www.youtube-nocookie.com/embed/FPa7M-AXxT4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="text-white py-20 relative z-40">
  <h2 class="font-display text-center text-display3 lg:text-display2 font-bold leading-tight uppercase text-white mb-8 lg:pt-12">
  Meet your <br>
  <span style="color: #d83252">category judges</span>
</h2>

<ul class="flex justify-center flex-wrap mx-auto text-center" style="max-width: 1600px">
        <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-vicegrip.png/1f1a1346e1031bfa470cec6d76a7c060.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-vicegrip.png/1f1a1346e1031bfa470cec6d76a7c060.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-vicegrip.png/9c98794cd940bc406aecdf551b60a06d.jpg" alt="Vice Grip Garage" src="/img/car-show-2021/judges/ics-vicegrip.png/9c98794cd940bc406aecdf551b60a06d.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Vice Grip Garage</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/ViceGripGarage" target="_blank"><img class=" lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/vicegripgarage/" target="_blank">
        <img class="object-contain lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-skidfactory.png/67230cb540ca4ee9a96255dea0e79e9f.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-skidfactory.png/67230cb540ca4ee9a96255dea0e79e9f.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-skidfactory.png/86651cd86177917f31fce117b1e20eaf.jpg" alt="The Skid Factory" src="/img/car-show-2021/judges/ics-skidfactory.png/86651cd86177917f31fce117b1e20eaf.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">The Skid Factory</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/theskidfactory" target="_blank"><img class=" lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/theskidfactory/" target="_blank">
        <img class="object-contain lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-neutraldrop.png/92faf63f1deeb3c6832793fe38c90183.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-neutraldrop.png/92faf63f1deeb3c6832793fe38c90183.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-neutraldrop.png/cc46b89476a8aea4d3bc961b16b6460d.jpg" alt="Neutral Drop" src="/img/car-show-2021/judges/ics-neutraldrop.png/cc46b89476a8aea4d3bc961b16b6460d.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Neutral Drop</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/NEUTRALDROP" target="_blank"><img class=" lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/neutraldroptv/" target="_blank">
        <img class="object-contain lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-regularcars.png/2ff41a1e71c899d0445ff4d83f8a9217.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-regularcars.png/2ff41a1e71c899d0445ff4d83f8a9217.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-regularcars.png/1cd97b4f77889e475c8a6483e65cdf41.jpg" alt="Regular Car Reviews" src="/img/car-show-2021/judges/ics-regularcars.png/1cd97b4f77889e475c8a6483e65cdf41.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Regular Car Reviews</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/user/RegularCars" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/regularcarreviews/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-sarahntuned.png/524715cff37180f59d24cc439d58edf0.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-sarahntuned.png/524715cff37180f59d24cc439d58edf0.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-sarahntuned.png/68347b966b2fd6548af811619fbb3d3d.jpg" alt="Sarah N Tuned" src="/img/car-show-2021/judges/ics-sarahntuned.png/68347b966b2fd6548af811619fbb3d3d.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Sarah N Tuned</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/SarahnTuned" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/sarahntuned/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-pfispeed.png/e07cc66fd927d9e42dc5bebcef63095d.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-pfispeed.png/e07cc66fd927d9e42dc5bebcef63095d.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-pfispeed.png/f3776bb0c47f1a61cf5b2a146c57ce19.jpg" alt="PFI Speed" src="/img/car-show-2021/judges/ics-pfispeed.png/f3776bb0c47f1a61cf5b2a146c57ce19.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">PFI Speed</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/PFISpeedtube" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/pfispeed/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-blackjack.png/59a7a19708901b37feae0263f55d4f39.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-blackjack.png/59a7a19708901b37feae0263f55d4f39.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-blackjack.png/74992861da21abae292c239deb436cf1.jpg" alt="Black Jack" src="/img/car-show-2021/judges/ics-blackjack.png/74992861da21abae292c239deb436cf1.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Black Jack</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/DEBOSSGARAGE" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/blackjackdeboss/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-junkyarddigs.png/5a396300d4a62032515586888dd3afec.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-junkyarddigs.png/5a396300d4a62032515586888dd3afec.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-junkyarddigs.png/47fece7ac03af3f771e11bf000b9bedf.jpg" alt="Junkyard Digs" src="/img/car-show-2021/judges/ics-junkyarddigs.png/47fece7ac03af3f771e11bf000b9bedf.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Junkyard Digs</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/JunkyardDigs" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/junkyard_digs/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-flyingsparks.png/7d4e8b61855bf29b7108c83bf68c1abf.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-flyingsparks.png/7d4e8b61855bf29b7108c83bf68c1abf.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-flyingsparks.png/59348dd663bfa1da7acd9f3678124b05.jpg" alt="Flying Sparks Garage" src="/img/car-show-2021/judges/ics-flyingsparks.png/59348dd663bfa1da7acd9f3678124b05.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Flying Sparks Garage</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/channel/UCSYfpT-hQRBeujVwHEMEsww" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/imemilyreeves/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-gearsandgasoline.png/4b244c7158484a0c3cd040e78f9ca504.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-gearsandgasoline.png/4b244c7158484a0c3cd040e78f9ca504.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-gearsandgasoline.png/4cd4957563686fb1af7c9b49ef04dd1f.jpg" alt="Gears and Gasoline" src="/img/car-show-2021/judges/ics-gearsandgasoline.png/4cd4957563686fb1af7c9b49ef04dd1f.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Gears and Gasoline</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/GearsandGasoline" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/gearsandgasoline/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-speedacademy.png/29efc5b41c3a6eec1f9570dc14f860a6.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-speedacademy.png/29efc5b41c3a6eec1f9570dc14f860a6.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-speedacademy.png/381d362413b15596c530bd2fc740ecbc.jpg" alt="Speed Academy" src="/img/car-show-2021/judges/ics-speedacademy.png/381d362413b15596c530bd2fc740ecbc.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Speed Academy</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/user/spdacademy" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/speedacademy/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-welkerfarms.png/57accd906b0e254f1e2d0734c9553f77.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-welkerfarms.png/57accd906b0e254f1e2d0734c9553f77.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-welkerfarms.png/3bf21437a029b3056fb2319296960d43.jpg" alt="Welker Farms" src="/img/car-show-2021/judges/ics-welkerfarms.png/3bf21437a029b3056fb2319296960d43.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Welker Farms</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/WelkerFarmsInc" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/welkerfarmsinc/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-tavarish.png/31bda4d8d41a9833236fae9bbd490534.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-tavarish.png/31bda4d8d41a9833236fae9bbd490534.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-tavarish.png/319f334eb081f176ec0b3c792695a7b3.jpg" alt="Tavarish" src="/img/car-show-2021/judges/ics-tavarish.png/319f334eb081f176ec0b3c792695a7b3.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Tavarish</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/c/tavarish" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/therealtavarish/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 lg:p-8">
    <div class="text-center transition-opacity">
        <picture>
      <source srcset="/img/car-show-2021/judges/ics-badobsession.png/39d65ebbaa2ca3eb5f50fb75582e1f8a.webp" type="image/webp" srcset="/img/car-show-2021/judges/ics-badobsession.png/39d65ebbaa2ca3eb5f50fb75582e1f8a.webp">
      <img class="mb-3 lazyloaded" style="border-radius: 15px;" src="/img/car-show-2021/judges/ics-badobsession.png/a3e012cbd369e643b513282a8f268ce0.jpg" alt="Bad Obsession Motorsport" src="/img/car-show-2021/judges/ics-badobsession.png/a3e012cbd369e643b513282a8f268ce0.jpg">
    </picture>


    <div class="text-xl uppercase mb-2">Bad Obsession Motorsport</div>
    <div class="text-sm flex justify-center">
      <a class="uppercase mr-6 opacity-50 hover:opacity-100" style="transform: scale(1.275);" href="https://www.youtube.com/user/badobsessionmsport" target="_blank"><img class=" ls-is-cached lazyloaded" src="/img/social-icons/Youtube.svg" alt="Youtube" src="/img/social-icons/Youtube.svg"></a>
      <a class="uppercase opacity-50 hover:opacity-100" href="https://www.instagram.com/badobsessionmsp/" target="_blank">
        <img class="object-contain ls-is-cached lazyloaded" style="aspect-ratio: 256 / 425" src="/img/social-icons/Instagram.svg" alt="Instagram" src="/img/social-icons/Instagram.svg">
      </a>
    </div>
  </div>
</li>

</ul>


<div class="text-white py-20 relative z-40">
  <h2 class="font-display text-center text-display3 lg:text-display2 font-bold leading-tight uppercase text-white mb-8 lg:pt-12">
  Categories
</h2>

<ul id="categories" class="flex justify-center flex-wrap mx-auto max-w-6xl text-center">
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-gearhead.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-gearhead.png">
      <h2 class="text-xl font-display">Gearhead Choice</h2>
            <div class="text-base">
        Could you be the most underrated builder making videos on YouTube? Enter your ride and let the voters decide!
      </div>
    </a>
  </ldiv>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-factree.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-factree.png">
      <h2 class="text-xl font-display">Factree</h2>
            <h3 class="uppercase mb-2">Judged by: Vice Grip Garage</h3>
            <div class="text-base">
        That OEM? Anything but original options on these jerry rigs.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-enginuity.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-enginuity.png">
      <h2 class="text-xl font-display">Enginuity</h2>
            <h3 class="uppercase mb-2">Judged by: The Skid Factory</h3>
            <div class="text-base">
        Unique engine swaps done with limited resources.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-stuntman.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-stuntman.png">
      <h2 class="text-xl font-display">Stuntman</h2>
            <h3 class="uppercase mb-2">Judged by: Neutral Drop</h3>
            <div class="text-base">
        You got what it takes to show up the kings of car carnage, folks?
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-roast-me.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-roast-me.png">
      <h2 class="text-xl font-display">Roast Me</h2>
            <h3 class="uppercase mb-2">Judged by: Regular Car Reviews</h3>
            <div class="text-base">
        Put your personality on display! I will eat your soul.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-tuner-taste.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-tuner-taste.png">
      <h2 class="text-xl font-display">Tuner Taste</h2>
            <h3 class="uppercase mb-2">Judged by: Sarah N Tuned</h3>
            <div class="text-base">
        Style points for the DIY tuner car that still gives it the beans.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-fastest-buck.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-fastest-buck.png">
      <h2 class="text-xl font-display">Fastest Buck</h2>
            <h3 class="uppercase mb-2">Judged by: PFI Speed</h3>
            <div class="text-base">
        Biggest power on the tightest budget!
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-tribute.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-tribute.png">
      <h2 class="text-xl font-display">Tribute</h2>
            <h3 class="uppercase mb-2">Judged by: Black Jack</h3>
            <div class="text-base">
        Kit cars and tenacious oddballs inspired by the greatest cars in the world.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-revive-n-drive.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-revive-n-drive.png">
      <h2 class="text-xl font-display">Revive N Drive</h2>
            <h3 class="uppercase mb-2">Judged by: Junkyard Digs</h3>
            <div class="text-base">
        Back from the dead, and back on the road.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-mad-props.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-mad-props.png">
      <h2 class="text-xl font-display">Mad Props</h2>
            <h3 class="uppercase mb-2">Judged by: Flying Sparks Garage</h3>
            <div class="text-base">
        Boats, planes, anything with a propeller.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-power-trip.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-power-trip.png">
      <h2 class="text-xl font-display">Power Trip</h2>
            <h3 class="uppercase mb-2">Judged by: Gears and Gasoline</h3>
            <div class="text-base">
        Purpose-built roadtrippers! Practicality is optional.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-hot-lap-hero.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-hot-lap-hero.png">
      <h2 class="text-xl font-display">Hot Lap Hero</h2>
            <h3 class="uppercase mb-2">Judged by: Speed Academy</h3>
            <div class="text-base">
        May or may not be streetable, but it's ready to rip on the track.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-git-r-dun.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-git-r-dun.png">
      <h2 class="text-xl font-display">Git-R-Dun</h2>
            <h3 class="uppercase mb-2">Judged by: Welker Farms</h3>
            <div class="text-base">
        Heavy duty mods for heavy duty jobs.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-super-modified.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-super-modified.png">
      <h2 class="text-xl font-display">Super Modified</h2>
            <h3 class="uppercase mb-2">Judged by: Tavarish</h3>
            <div class="text-base">
        Clean, simple but elegant – and very, VERY powerful.
      </div>
    </div>
  </li>
    <li class="w-1/2 md:w-1/3 lg:w-1/4 p-4 lg:p-8">
    <div class="text-center transition-opacity">
      <img class="mb-3 object-contain lazyloaded" style="aspect-ratio: 224 / 372;" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-bracketry.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-bracketry.png">
      <h2 class="text-xl font-display">Bracketry</h2>
            <h3 class="uppercase mb-2">Judged by: Bad Obsession Motorsport</h3>
            <div class="text-base">
        OCD points for the best brackets holding your rig together.
      </div>
    </div>
  </li>

</ul>

<section style="background-image: url(/img/ics2021/halfskull.webp);" class="bg-left-top bg-no-repeat bg-contain">



  <div class="max-w-4xl mx-auto px-4 py-12">
    <h2 class="font-display text-display2 uppercase font-bold text-center text-white mb-8 " style="color: #d83252">
      Awards
    </h2>
    <ul class="flex flex-wrap justify-between -mx-4 text-center">
      <li class="w-1/2 w-1/3 px-4 mb-8 pt-24">
        <img class=" lazyloaded" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-2nd-boss2021.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-2nd-boss2021.png">
        <h2 class="font-display font-bold text-lg">Second Boss</h2>
        <h3 class="text-display3" style="color: #d83252">$1000</h3>
        <span class="uppercase">Gift Card</span>
      </li>
      <li class="w-1/2 w-1/3 px-4 mb-8">
        <img class=" lazyloaded" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-megaboss2021-2.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-megaboss2021-2.png">
        <h2 class="font-display font-bold text-lg">Megaboss</h2>
        <h3 class="text-display3" style="color: #d83252">$2000</h3>
        <span class="uppercase">Gift Card</span>
      </li>
      <li class="w-1/2 w-1/3 px-4 mb-8 pt-24">
        <img class=" lazyloaded" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-3rd-boss2021.png" alt="Trophy" src="https://debossgarage.s3.amazonaws.com/car-show-2021/trophies/ics-3rd-boss2021.png">
        <h2 class="font-display font-bold text-lg">Third Boss</h2>
        <h3 class="text-display3" style="color: #d83252">$500</h3>
        <span class="uppercase">Gift Card</span>
      </li>
    </ul>
  </div>

  <div class=" text-white" style="">
    <div class="container mx-auto px-2 py-12 lg:py-20">
      <h2 class="font-display text-xxl uppercase font-bold text-center text-white mb-8">
      Summit Racing Gift Card
      </h2>
      <div class="max-w-xl mx-auto pb-10 md:text-xl text-center">
        <p>Once our judges have picked winners for all categories, judges will vote in a ranked ballot to award the <strong>MEGABOSS Grand Champion</strong> and the runners up.</p>
      </div>
      <img class="mx-auto w-full max-w-md rounded-lg rounded border-gray-400 border-2 border-opacity-50 lazyloaded" src="https://debossgarage.s3.amazonaws.com/car-show-2021/summit-racing-gift-card.jpg" alt="Grand Prize" src="https://debossgarage.s3.amazonaws.com/car-show-2021/summit-racing-gift-card.jpg">
    </div>
  </div>

</section>

<div id="finalists" class="container px-4 mx-auto relative">

    <h1 class="text-center py-6 text-display3 uppercase">Your ICS 2021 Finalists</h1>
    <div class="max-w-4xl mx-auto text-center">
            <div class="pt-4 my-4 border-grey100 border-4">
        <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616500671-1960ish-homemade-sports-racer-ratso-italiano">
          <div class="w-1/2">
            <div class="mx-auto" style="max-width: 160px">
              <div class="relative" style="padding-bottom: 165.6804734%">
                <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_megaboss2021.png" alt="IQCS 2021 Boss award">
              </div>
            </div>
          </div>
          <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
            <small class="block text-sm uppercase mb-2 opacity-25">Mega Boss</small>
            1960ish Homemade Sports Racer Ratso Italiano
          </div>
        </a>
      </div>
            <div class="pt-4 my-4 border-grey100 border-4">
        <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615518873-67-mini-cooper-r1-powered">
          <div class="w-1/2">
            <div class="mx-auto" style="max-width: 160px">
              <div class="relative" style="padding-bottom: 165.6804734%">
                <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_2nd_boss.png" alt="IQCS 2021 Boss award">
              </div>
            </div>
          </div>
          <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
            <small class="block text-sm uppercase mb-2 opacity-25">2nd Boss</small>
            67 Mini Cooper R1 Powered Rwd
          </div>
        </a>
      </div>
            <div class="pt-4 my-4 border-grey100 border-4">
        <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615465236-1927-essex-coupe">
          <div class="w-1/2">
            <div class="mx-auto" style="max-width: 160px">
              <div class="relative" style="padding-bottom: 165.6804734%">
                <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_3rd_boss.png" alt="IQCS 2021 Boss award">
              </div>
            </div>
          </div>
          <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
            <small class="block text-sm uppercase mb-2 opacity-25">3rd Boss</small>
            1927 Essex Coupe
          </div>
        </a>
      </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616702934-1984-chevrolet-k10?category=gearhead">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_gearhead.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Gearhead Choice</small>
              1984 Chevrolet K10 (GREAT STORY)
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/Ma4mmuA11rw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615465236-1927-essex-coupe?category=factree">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_factree.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Factree</small>
              1927 Essex Coupe
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/Wv3m0jI4Z-8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616546486-1965-ford-f-100?category=enginuity">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_enginuity.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Enginuity</small>
              Ratruck   rolling metal art
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/L5ivLQDymuE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616729475-1993-ferd-f1-chitty?category=stuntman">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_stuntman.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Stuntman</small>
              1993 FERD F1-CHITTY
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/hnl0QDnhh7Y" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616465090-1999-chevy-k2500-suburban-dually?category=roast-me">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_roast_me.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Roast Me</small>
              1999 Chevy K2500 Suburban Dually
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/E_fv6bBB0k0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615506225-1992-toyota-celica-alltrac?category=tuner-taste">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_tuner_taste.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Tuner Taste</small>
              1992 Toyota Celica Alltrac
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/n--NZJOL77c" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616469323-1992-eagle-talon?category=fastest-buck">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_fastest_buck.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Fastest Buck</small>
              1992 Eagle Talon
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/PqVqr5EQvYY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616500671-1960ish-homemade-sports-racer-ratso-italiano?category=tribute">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_tribute.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Tribute</small>
              1960ish homemade sports racer Ratso Italiano
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/O3lx0aluxHQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1617045392-1970-plymouth-sport-fury-gt?category=revive-n-drive">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_revive_n_drive.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Revive N Drive</small>
              1970 Plymouth Sport Fury GT
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/roG3MFnMri8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616513713-1946-j3-piper-cub?category=mad-props">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_mad_props.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Mad Props</small>
              1946 J3 Piper Cub
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/1lYqrhQ_qcc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615523830-1972-f600-dt466?category=power-trip">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_power_trip.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Power Trip</small>
              1972 F600 Atomic Annie
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/aJqAUoz25Vc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616295099-1986-monte-carlo-ss?category=hot-lap-hero">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_hot_lap_hero.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Hot Lap Hero</small>
              1986 Monte Carlo SS
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/7m18dvEpmzQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1616466619-1995-dodge-cummins-6x6?category=git-r-dun">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_git_r_dun.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Git-R-Dun</small>
              1995 Dodge Cummins 6x6
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/VGQhIk5GJHY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615518873-67-mini-cooper-r1-powered?category=super-modified">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_super_modified.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Super Modified</small>
              67 Mini Cooper R1 Powered RWD
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/47lnoW0G-Rk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>

        </div>

                      <div class="pt-4 my-4 border-grey100 border-4">
          <a class="flex items-center justify-center text-left text-xl sm:text-xl md:text-xxl leading-none" href="/nation/projects/1615500349-austin-mini-vr6t-syncro?category=bracketry">
            <div class="w-1/2">
              <div class="mx-auto" style="max-width: 160px">
                <div class="relative" style="padding-bottom: 165.6804734%">
                  <img class="mr-4 max-w-full pin-t pin-r pin-b pin-l absolute" src="/img/awards/ics2021_bracketry.png" alt="IQCS 2021 Boss award">
                </div>
              </div>
            </div>
            <div class="w-1/2 md:w-3/4 border-l-2 border-grey100 p-4 md:pl-12">
              <small class="block text-sm uppercase mb-2 opacity-25">Bracketry</small>
              Austin mini vr6T syncro
            </div>
          </a>

          <div class="mt-8">
            <div>
              <div class="relative bg-grey200" style="padding-bottom: 56.25%">
                <iframe class="absolute w-full h-full top-0 right-0 bottom-0 left-0" src="https://www.youtube-nocookie.com/embed/jb6NJmQ2kk4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
`

export const Page = () => {
  return (
    <>
      <Head>
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Orbitron:wght@800&display=swap'
          rel='stylesheet'
        />
        <link href='/css/tailwindLegacy.css' rel='stylesheet' />
      </Head>
      <NextSeo title='Internetional Car Show 2021' />
      <div dangerouslySetInnerHTML={{ __html: theHTML }}></div>
    </>
  )
}

export default Page
